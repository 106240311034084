import { FC, ReactNode } from 'react';

import { position } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled, { css } from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import { BoxProps } from 'modules/Ui/Box/Box';
import { IconCross } from 'modules/Ui/Icons';

import OnlyIconButton from '../Form/OnlyIconButton';
import messages from './messages';

interface PikitoProps extends BoxProps {
  arrowSize?: number;
  arrowInnerSize?: number;
  backgroundColor?: string;
  borderColor?: string;
  children?: ReactNode;
  color?: string;
  direction?: 'left' | 'top' | 'bottom';
  hArrowPosition?: string;
  onClose?: () => void;
  width?: number | string;
}

const leftPikito = css<PikitoProps>`
  &:after,
  &:before {
    right: 100%;
    top: 65%;
  }

  &:after {
    border-color: transparent;
    ${({ backgroundColor }) =>
      backgroundColor && `border-right-color: ${backgroundColor};`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `border-width: ${arrowInnerSize}px;`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `margin-top: -${arrowInnerSize}px;`}
  }

  &:before {
    border-color: transparent;
    ${({ borderColor }) => borderColor && `border-right-color: ${borderColor};`}
    ${({ arrowSize }) => arrowSize && `border-width: ${arrowSize}px;`}
	  ${({ arrowSize }) => arrowSize && `margin-top: -${arrowSize}px;`}
  }
`;

const topPikito = css<PikitoProps>`
  &:after,
  &:before {
    bottom: 100%;
    ${({ hArrowPosition }) => hArrowPosition && `left: ${hArrowPosition};`}
  }

  &:after {
    border-color: transparent;
    ${({ backgroundColor }) =>
      backgroundColor && `border-bottom-color: ${backgroundColor};`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `border-width: ${arrowInnerSize}px;`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `margin-left: -${arrowInnerSize}px;`}
  }

  &:before {
    border-color: transparent;
    ${({ borderColor }) =>
      borderColor && `border-bottom-color: ${borderColor};`}
    ${({ arrowSize }) => arrowSize && `border-width: ${arrowSize}px;`}
	  ${({ arrowSize }) => arrowSize && `margin-left: -${arrowSize}px;`}
  }
`;

const bottonPikito = css<PikitoProps>`
  &:after,
  &:before {
	  top: 100%;
	  left: 50%;
  }

  &:after {
    border-color: transparent;
    ${({ backgroundColor }) =>
      backgroundColor && `border-top-color: ${backgroundColor};`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `border-width: ${arrowInnerSize}px;`}
    ${({ arrowInnerSize }) =>
      arrowInnerSize && `margin-left: -${arrowInnerSize}px;`}
  }

  &:before {
    border-color: transparent;
    ${({ borderColor }) => borderColor && `border-top-color: ${borderColor};`}
    ${({ arrowSize }) => arrowSize && `border-width: ${arrowSize}px;`}
    ${({ arrowSize }) => arrowSize && `margin-left: -${arrowSize}px;`}
  }
`;

const StyledPikito = styled(Box)<PikitoProps>`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
  ${position}

  &:after,
  &:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
  }

  &:after {
    border-color: rgba(224, 240, 255, 0);
    ${({ arrowSize }) => arrowSize && `border-width: ${arrowSize};`}
  }

  &:before {
    ${({ arrowSize }) => arrowSize && `border-width: calc(${arrowSize} + 7px);`}
    border-color: rgba(224, 240, 255, 0);
  }

  ${({ direction }) => direction === 'top' && topPikito}
  ${({ direction }) => direction === 'left' && leftPikito}
  ${({ direction }) => direction === 'bottom' && bottonPikito}
`;

export const PikitoContainer: FC<PikitoProps> = ({
  arrowSize = 10,
  arrowInnerSize = arrowSize - 1,
  backgroundColor,
  borderColor,
  children,
  color,
  direction = 'top',
  hArrowPosition = '50%',
  onClose,
  width = 332,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <StyledPikito
      backgroundColor={backgroundColor || theme.colors.brand200A60}
      borderColor={borderColor ?? theme.colors.brand200A60}
      color={color ?? theme.colors.brand200A60}
      display={onClose && 'grid'}
      fadeIn
      position="relative"
      gridTemplateColumns={onClose && '1fr 32px'}
      columnGap={onClose && '12px'}
      width={width}
      hArrowPosition={hArrowPosition}
      {...{ arrowSize, arrowInnerSize, direction, ...rest }}
    >
      {children}
      {onClose && (
        <OnlyIconButton
          alignSelf="start"
          ariaLabel={t(messages.buttonClose)}
          icon={<IconCross size={20} />}
          iconHoverColor="32px"
          onClick={onClose}
          variant="gray"
          size={32}
        />
      )}
    </StyledPikito>
  );
};

StyledPikito.defaultProps = {
  borderRadius: '8px',
  borderStyle: 'solid',
  borderWidth: '1px',
};
