import {
  FC,
  ReactNode,
  DetailedHTMLProps as Props,
  AnchorHTMLAttributes as Attributes,
} from 'react';

import {
  border,
  color,
  flexbox,
  gridArea,
  gridColumn,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  layout,
  position,
  shadow,
  space,
  typography,
  system,
} from 'styled-system';

import {
  buttonPrimaryStyles,
  buttonSecondaryStyles,
  buttonStrokeStyles,
  buttonCustomIconTextStyle,
  buttonStrokeTransparentStyles,
} from 'modules/Theme/mixins/buttonImports';
import { buttonStyles } from 'modules/Theme/mixins/buttonStyles';
import styled from 'modules/Theme/styled-components';
import { BoxProps } from 'modules/Ui/Box/Box';

import Box from '../../Box';
import A from '../../Html/A';
import hoverStyle from '../hoverStyle';

interface ExternalLinkProps extends BoxProps {
  ariaLabel?: string;
  children?: string | ReactNode;
  hoverUnderline?: boolean;
  href: string | undefined;
  icon?: string | ReactNode;
  iconSize?: number;
  target?: 'blank' | 'self' | 'parent' | 'top';
  textDecoration?: 'underline' | 'none';
  to?: string;
  type?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'strokeTransparent'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
}

const StyledLink = styled(A)<ExternalLinkProps>`
  ${({ variant }) => variant && buttonStyles}
  ${({ icon }) => icon && buttonCustomIconTextStyle}
  ${({ variant }) => variant === 'primary' && buttonPrimaryStyles}
  ${({ variant }) => variant === 'secondary' && buttonSecondaryStyles}
  ${({ variant }) => variant === 'stroke' && buttonStrokeStyles}
  ${({ variant }) =>
    variant === 'strokeTransparent' && buttonStrokeTransparentStyles}
  ${({ hoverUnderline }) => hoverUnderline && hoverStyle}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
  ${color}
  ${border}
  ${flexbox}
  ${gridArea}
  ${gridColumn}
  ${gridTemplateAreas}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
  })}
`;

const ExternalLink: FC<
  ExternalLinkProps & Props<Attributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({
  ariaLabel,
  children,
  hoverUnderline,
  href,
  icon,
  iconSize,
  rel,
  target,
  textDecoration,
  to,
  type,
  variant,
  ...rest
}) => {
  return (
    <StyledLink
      aria-label={ariaLabel}
      variant={variant}
      hoverUnderline={hoverUnderline}
      rel={rel}
      type={type}
      target={target ? `_${target}` : undefined}
      textDecoration={textDecoration}
      {...{
        icon: !!icon,
        ...rest,
      }}
      href={to}
    >
      {icon && <>{icon}</>}
      {variant ? (
        <Box
          color="inherit"
          fontSize="inherit"
          position="relative"
          tag="span"
          whiteSpace="nowrap"
        >
          {children}
        </Box>
      ) : (
        <>{children}</>
      )}
    </StyledLink>
  );
};

export default ExternalLink;
